<template>
  <div>
    <div class="flex mb-4 justify-between items-center">
      <h1 class="font-medium text-3xl">{{ $t("tasks") }}</h1>
      <button v-if="role != 99" @click="open = true"
        class="bg-green-500 px-2 py-0.5 lg:py-1.5 lg:px-3 rounded text-gray-200">
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table :data="tasks" border v-loading="loading" style="width: 100%" class="rounded-lg shadow-md">
          <el-table-column fixed align="center" prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="name" :label="$t('name')" min-width="180">
          </el-table-column>
          <el-table-column prop="description" :label="$t('description')" min-width="180">
          </el-table-column>
          <el-table-column :label="$t('location')" min-width="180">
            <template slot-scope="scope">
              {{ tableLocation(scope.row.id) }}
            </template>
          </el-table-column>
          <el-table-column v-if="role != 99" :label="$t('actions')" fixed="right" align="center" :width="actionsWidth">
            <template slot-scope="scope">
              <el-button size="mini" @click="openEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
                <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
              </el-button>
              <el-popconfirm :confirm-button-text="$t('yes')" :cancel-button-text="$t('no_thanks')" icon="el-icon-info"
                icon-color="red" :title="$t('you_want_to_delete')" class="ml-2" @confirm="TaskDelete(scope.row.id)">
                <el-button slot="reference" size="mini" type="danger">
                  <i class="el-icon-delete"></i>
                  <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination background layout="prev, pager, next" hide-on-single-page @prev-click="Prev" @next-click="Next"
            @current-change="handleCurrentChange" :total="totalCount">
          </el-pagination>
        </div>
      </div>

      <el-dialog :visible.sync="open" :with-header="false" size="400px" @close="CloseDrawer" v-if="role != 99">
        <h1 class="px-4 py-2 text-3xl font-bold text-111E52 text-center">EXSERVICE</h1>
        <div class="p-4">
          <el-form ref="task" label-position="top" :model="task" label-width="120px" @submit.prevent.native>
            <h1 class="mb-2 font-medium text-xl lg:mb-4">{{ $t("add_task") }}</h1>
            <el-form-item class="ml-2" prop="corrective_type" style="margin-bottom: 24px" :label="$t('corrective_type')" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }">
              <el-select v-model="task.corrective_type" class="w-full" filterable :placeholder="$t('select')">
                <el-option v-for="task_type in corrective_type" :key="task_type.id" :label="task_type.name"
                  :value="task_type.id">
                </el-option>
              </el-select>
            </el-form-item>
            <hr class="mb-4">
            <h3 class="text-lg mb-2">{{ $t("russian") }}</h3>
            <el-form-item class="ml-2" prop="name_ru" style="margin-bottom: 16px" :label="$t('name')" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }">
              <el-input :placeholder="$t('input')" v-model="task.name_ru" clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="ml-2" prop="description_ru" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }" style="margin-bottom: 16px" :label="$t('description')">
              <el-input :placeholder="$t('input')" v-model="task.description_ru" clearable type="textarea" :rows="12">
                >
              </el-input>
            </el-form-item>
            <el-form-item class="ml-2" prop="taskLocation_ru" style="margin-bottom: 16px" :label="$t('location')" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }">
              <el-select v-model="task.taskLocation_ru" class="w-full" multiple filterable allow-create
                default-first-option :placeholder="$t('select')">
                <el-option v-for="location in LOCATIONS_RU" :key="location.id" :label="location.name_ru"
                  :value="location.name_ru">
                </el-option>
              </el-select>
            </el-form-item>
            <hr class="mb-4 mt-6">
            <h3 class="text-lg mb-2">{{ $t("uzbek") }}</h3>
            <el-form-item class="ml-2" prop="name_uz" style="margin-bottom: 16px" :label="$t('name')" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }">
              <el-input :placeholder="$t('input')" v-model="task.name_uz" clearable>
              </el-input>
            </el-form-item>
            <el-form-item class="ml-2" prop="description_uz" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }" style="margin-bottom: 16px" :label="$t('description')">
              <el-input :placeholder="$t('input')" v-model="task.description_uz" clearable type="textarea" :rows="12">
                >
              </el-input>
            </el-form-item>
            <el-form-item class="ml-2" prop="taskLocation_uz" style="margin-bottom: 16px" :label="$t('location')" :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }">
              <el-select v-model="task.taskLocation_uz" class="w-full" multiple filterable allow-create
                default-first-option :placeholder="$t('select')">
                <el-option v-for="location in LOCATIONS_UZ" :key="location.id" :label="location.name_uz"
                  :value="location.name_uz">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="text-right mb-10">
              <el-button v-if="!edit" @click="addTask" type="primary" size="medium" :loading="loading">
                {{ $t("add") }}
              </el-button>
              <el-button v-else @click="editTask" type="primary" size="medium" :loading="loading">
                {{ $t("save") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Task",
  data() {
    return {
      actionsWidth: 270,
      open: false,
      edit: false,
      count: 1,
      totalCount: 0,
      loading: false,
      id: "",
      edit_task: "",
      tasks: [],
      LOCATIONS_RU: "",
      LOCATIONS_UZ: "",
      corrective_type: "",
      task: {
        name_uz: "",
        name_ru: "",
        description_uz: "",
        description_ru: "",
        task_location: [],
        taskLocation_uz: [],
        taskLocation_ru: [],
        corrective_type: null
      },
      role: localStorage.role,
    };
  },
  created() {
    this.getTasks();
    this.getLocations();
    this.getCorrectiveType();
    window.innerWidth < 992 ? (this.actionsWidth = 120) : "";
  },
  watch: {
    '$i18n.locale': function() {
      this.getTasks();
    }
  },
  methods: {
    async getTasks() {
      this.loading = true;
      if (this.$i18n.locale === 'uz') {
        await axios
          .get(`tasks/${this.count === 1 ? "" : "?page=" + this.count}`)
          .then((response) => {
            this.totalCount = response.data.count;
            this.tasks = response.data.results;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        try {
          const storageToken = localStorage.getItem("token");
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/ru/api/tasks/${this.count === 1 ? "" : "?page=" + this.count}`, {
            method: 'GET',
            headers: {
              'ngrok-skip-browser-warning': "any",
              'Authorization': storageToken ? `Token ${storageToken}` : "",
              'Content-Type': 'application/json'
          }
            });

          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }

          const data = await response.json();
          this.totalCount = data.count;
          this.tasks = data.results;
          this.loading = false;
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          this.loading = false;
        }
      }
    },

    async getLocations() {
      await axios.get("locations/?no_page").then((response) => {
        this.LOCATIONS_UZ = response.data.filter((obj, index, array) =>
          index === array.findIndex((o) => (o.name_uz === obj.name_uz))
        );

        this.LOCATIONS_RU = response.data.filter((obj, index, array) =>
          index === array.findIndex((o) => (o.name_ru === obj.name_ru))
        );
      });
    },
    async getCorrectiveType() {
      await axios.get("corrective-types/?no_page").then((response) => {
        this.corrective_type = response.data;
      });
    },
    Prev() {
      this.count--;
      this.getTasks();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getTasks();
    },
    Next() {
      this.count++;
      this.getTasks();
    },
    CloseDrawer() {
      this.edit = false;
      this.clearTask();
      this.$refs.task.clearValidate();
    },
    tableLocation(id) {
      return this.tasks.find(el => el.id === id).task_location.filter((obj, index, array) =>
        index === array.findIndex((o) => o.name === obj.name)
      ).map(obj => obj.name).join(', ');
    },
    openEdit(id) {
      this.clearTask();
      this.open = true;
      this.edit = true;
      this.id = id;
      this.edit_task = this.tasks.find((data) => data.id === id);
      Object.keys(this.task).forEach((key) => {
        if (key !== 'taskLocation_ru' && key !== 'taskLocation_uz') {
          if (Array.isArray(this.edit_task[key]) && this.edit_task[key].length) {

            this.edit_task[key] = this.edit_task[key].filter((obj, index, array) =>
              index === array.findIndex((o) => (o.name_uz === obj.name_uz || o.name_ru === obj.name_ru))
            );
            this.edit_task[key].forEach(el => {
              this.task.taskLocation_ru.push(el.name_ru);
              // this.task.taskLocation_ru.push(this.LOCATIONS.find(data => data.id === el.id).name_ru);

              this.task.taskLocation_uz.push(el.name_uz);
              // this.task.taskLocation_uz.push(this.LOCATIONS.find(data => data.id === el.id).name_uz);

            })
          }

          if (typeof this.edit_task[key] === 'object' && key === 'corrective_type') {
            this.task[key] = this.edit_task[key].id;
          } else if (key !== 'task_location') {
            this.task[key] = this.edit_task[key];
          }
        }
      });

    },
    editTask() {
      this.$refs.task.validate((valid) => {
        if (valid && this.task.taskLocation_ru.length === this.task.taskLocation_uz.length) {
          this.locationTrigger();
          this.loading = true;
          let task_copied = { ...this.task };
          delete task_copied.taskLocation_uz;
          delete task_copied.taskLocation_ru;
          axios
            .patch(`tasks/${this.id}/`, task_copied)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getTasks();
              this.getLocations();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        } else {
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("locations_not_equal"),
          });
        }
      });
    },
    addTask() {
      this.$refs.task.validate((valid) => {
        if (valid && this.task.taskLocation_ru.length === this.task.taskLocation_uz.length) {
          this.locationTrigger();
          this.loading = true;
          let task_copied = { ...this.task };
          delete task_copied.taskLocation_uz;
          delete task_copied.taskLocation_ru;
          axios
            .post("tasks/", task_copied)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getTasks();
              this.getLocations();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        } else {
            this.$notify.error({
            title: this.$t("error"),
            message: this.$t("locations_not_equal"),
          });
        }
      });
    },
    async TaskDelete(id) {
      this.loading = true;
      await axios
        .delete(`tasks/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getTasks();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    locationTrigger() {
      for (let i = 0; i < this.task.taskLocation_ru.length; i++) {
        if(this.LOCATIONS_UZ.some(obj => obj.name_uz === this.task.taskLocation_uz[i])) {
          let id =this.LOCATIONS_UZ.find(el => el.name_uz === this.task.taskLocation_uz[i]).id;
          this.task.task_location.push({ id, name_uz: this.task.taskLocation_uz[i], name_ru: this.task.taskLocation_ru[i] })
        } else if(this.LOCATIONS_RU.some(obj => obj.name_ru === this.task.taskLocation_ru[i])) {
          let id = this.LOCATIONS_RU.find(el => el.name_ru === this.task.taskLocation_ru[i]).id;
          this.task.task_location.push({ id, name_uz: this.task.taskLocation_uz[i], name_ru: this.task.taskLocation_ru[i] })
        } else {
          this.task.task_location.push({ name_uz: this.task.taskLocation_uz[i], name_ru: this.task.taskLocation_ru[i] })
        }
      }
    },
    clearTask() {
      Object.keys(this.task).forEach((key) => {
        if (Array.isArray(this.task[key])) {
          this.task[key] = [];
        } else {
          this.task[key] = "";
        }
      });
    }
  },
};
</script>

<style lang="scss"></style>
